import React from 'react'
import Layout from '../../components/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import episodeArt from '../../images/song_art/episode4.png'

const Episode4 = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">young love - time to start shakin' things up around here</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container podcast is-fluid">
        <div className="columns name">
          <div className="column">
            <h1 className="is-size-4">Episode 4: Gonna flex my mantle, make the ground shake</h1>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <img src={episodeArt} className="episode-art" alt="One World Grows Larger (asteroid hurtling through space with distant sun in background)" />
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/8071292/height/50/theme/legacy/thumbnail/no/preload/no/direction/backward/" height="50" width="100%" scrolling="no"></iframe>
          </div>
          <div className="column is-two-thirds transcript">
            <p>
              Welcome again to Planet and Sky, a cosmic love story. In our previous shows, a scientific team went to investigate a strange signal emanating from a remote planet. A male and female scientist, along with their research team, discovered that the barren planet held ancient memories of a time when it had had an atmosphere – and even more startlingly – that the planet remembered meeting this atmosphere and falling in love with it. As the planet transmitted its memories into the present, the male and female scientists sent to investigate began experiencing a strange affinity for one another. And deep within the planet’s ancient memories was a time when three colonies of microbes had arrived – microbes that would have their own role to play later when the planet became one with its sky.
            </p>

            <p>
              Planet had a message for his new love…
            </p>

            <p>
              “I got the heat building up down deep - gonna build a mountain or two. Gonna flex my mantle, make the ground shake. Gonna get tectonic on you.”
            </p>

            <p>
              The heat had been building within his mantle. Some came from radioactive decay, but most was the result of the compression of his great mass. The rising temperature within his depths melted the rock into boiling cauldrons deep below his crust. New material crashing in from above added to the dynamic forces he felt within. Life with Sky was turning out to be a powerful experience. He felt a desire to interact with her in new ways. He discovered that he could channel some of his deep pools of melted rock into vigorous upward eruptions. In the resulting clouds of cooling ash and radiating gases and debris Sky cavorted in delight. Sometimes she would even provide a sparkling light show for him, with electrical discharges in the midst of the clouds he created. She would revel in the materials he would provide for her, mixing them with those from above to enrich her substance, generating new colors and scents for him to appreciate.
            </p>

            <p>
              Planet also discovered that he could shift whole blocks of his crust and set them floating upon his surface in slow but powerful currents of movement. When one would crash into another the momentous collisions would often result in soaring mountains and deep trenches. Sometimes he could make one section of crust rise over another making powerful earthquakes occur, causing him to shudder in delight. Sky would be thrown into a frenzy on these soaring heights and would whirl and dance. She would spontaneously weep with joy and the tears would run down the slopes carving out great valleys and creating wide seas merging into oceans.
            </p>

            <p>
              Planet remembered how his world had changed so quickly and sang,
            </p>

            <p>
              “I used to think it was oh so simple, perfection was my only aim. Now you’ve got me all torn up inside, girl - I don’t even know my name!”
            </p>

            <p>
              Sky also remembered the joyful serenity of her earliest memories, when she’d first separated out of the dust and gas that congealed into a solid mass beneath her. There was a peace that had been lost, replaced by a vigorous dance with her powerful husband below.
            </p>

            <p>
              She sang, “Yeah I hear you but it works both ways. I was so happy to be free. Now you’ve got me swirling out of control. Can’t tell who’s you and who is me!”
            </p>

            <p>
              She loved the dynamic interactions she had with her great spherical love, but also missed the gentle existence she’d enjoyed before their union. Her turbulence was thrilling, but also disorienting and she would sometimes wonder exactly what she’d gotten herself into.
            </p>

            <p>
              She continued, “My air was gentle and my breeze was light - I felt at peace and free from harm. Now every time that you let off some steam boy, I become a raging storm.”
            </p>

            <p>
              Planet was getting pummeled now with a relentless bombardment. Whatever happened to Sky’s promise to protect him from cosmic debris? Still, he couldn’t begrudge his new mate for her failure. She was a delicate and airy thing, and some of these boulders crashing down could hardly be kept at bay by her thin atmosphere.
            </p>

            <p>
              Planet pondered wistfully, “What does it mean to be one with you? Do I lose all I thought I had? It seems so natural as we melt into each other. This greater being is not so bad.
            </p>

            <p>
              Sky did her best, heating the intruders as they fell and succeeding at breaking up the smaller ones and not letting them impact her dear Planet. It resulted in a pretty show for him with bright streaks and occasional explosions. Just another way for her to show off. Sometimes she found the contents of these guests to be enriching in some ways. She would find the best ingredients and sprinkle them upon the surface of her love. Surely he could hardly object?
            </p>

            <p>
              Planet did have his concerns, “But these convulsions make me wonder, these stones that smash me from above. Is there a downside to this union? What kind of bargain is this love?”
            </p>

            <p>
              Another sizable chunk of ice and stone came tearing through and impacted Planet violently. It exploded forcefully with a tremendous crash and part of it lodged deep within him while the remainder spread radially in all directions and left an impressive crater. Sky got to work filling it with her nurturing rains.
            </p>

            <p>
              Planet occasionally remembered his geometric perfection and how this was the ideal he had cherished for so long. Now he was scarred and warped in so many ways and he could barely remember the peace and freedom of being the center of his quiet universe.
            </p>

            <p>
              Sky reflected on the peaceful times before, “Yes I am thankful for foundation, and feel a bliss to bond with you, but I miss the simple joy of floating… without a care, without a clue.”
            </p>

            <p>
              For the most part, though, she loved this new and far richer life. With each new eruption or incoming intrusion from deep space, she found her composition gaining in complexity and would often be surprised at what forms she could take as she flowed around her world. In addition to the explosive electrical discharges she could set off, she found that by varying her pressure in different places she could generate enormous cyclones with powerful winds and punishing rains. She enjoyed being such a powerful influence upon her partner.
            </p>

            <p>
              There were great depths and extraordinary challenges in this new relationship. Not all of Planet’s eruptions were entirely helpful. Sometimes he seemed just as intent on creating a mess as he did in building wonderful gifts for her. Of course, she wasn’t entirely a consistent model of perfection herself. Occasionally Planet would go to the trouble of building incredible monuments that would bring rapture to her being, then the next thing he knew she was wearing them down to nothing. Sometimes he’d get so upset with her he would just go quiet and stop giving her anything at all. She’d find herself growing cold enough to freeze his surface or bury it in thick white layers of ice and snow, then changing her mind and melting it all so fast it would create damaging floods.
            </p>

            <p>
              Planet could never go too long without building something new. It was in his nature to be an architect. Some of his creations were fantastic and beautiful, while others would be rather flat and uninspired. Sky sometimes disliked some of the things he made and would just wear them away or bury them in depths of water so she wouldn’t have to look at them. When he did create gems for her, though, she would decorate them in constantly shifting bands of radiant beauty. She knew how to put on a show for him and just couldn’t resist making him happy.
            </p>

            <p>
              She continued her song, “...but I must have you know my dear, my love for you exceeds all bounds. The care we feel now for each other - fuels the power that we’ve found.”
            </p>

            <p>
              “Eons of anger,” sang Planet.
            </p>

            <p>
              “Confusion and pain,” rejoined Sky.
            </p>

            <p>
              “The fire down below…” sang her partner.
            </p>

            <p>
              “and the pouring rain.” she completed.
            </p>

            <p>
              Then together, “The power of creation, the will to destroy, sudden understanding… eons of joy!”
            </p>

            <p>
              Planet and Sky were having a splendid time working together on creating ever more fantastic landscapes and getting in occasional knock down drag out fights, and hardly noticed when something altogether unexpected occurred. Among the lush and fertile seas and quiet coves along the shorelines, something was stirring. It appeared that at least one of those incoming projectiles had contained more than just ice and rock. There were patterns of chemicals that seemed to be growing in strange and miraculous ways. The two lovers were just too focused on the big picture to notice what was happening on a very small scale, that some of the chemical compounds inhabiting the rocks that crashed into the planet were somehow making copies of themselves. At first it was a very limited phenomenon, but once it got going it took on a momentum of its own. The copies were turning into larger and larger collections of compounds and some of these were beginning to demonstrate some quite interesting behaviors.
            </p>

            <p>
              Some were showing a capability to move on their own, growing tiny hairs which could propel them toward energy sources. Using this energy they were developing new features that allowed even more complicated behaviors, like processing the light of the sun to create energy for new growth. Soon these compounds were differentiating into whole groups of different substances with different functions. Some developed fins and scales and learned to swim faster and grow stronger. Others turned fins into legs and crept out onto the land. Another group turned into a material that reached high into the air and forked off into branches and twigs with green leaves. Before long, Planet and Sky had a whole family of children of different shapes and sizes and they were adding a whole new dimension to the ways that their world could be enriched. The land and seas were becoming alive.
            </p>

            <p>
              Planet and Sky were now much more than rock and air. They had become a living and breathing biosphere. Sky was aglow with joy and Planet was as proud as a new father could be. They didn’t have the slightest idea how it had happened - neither had taken a course in sex education for planetary systems - but they happy to figure things out together as they went.
            </p>

            <p>
              Our cosmic couple is off to a vigorous start to their astronomical marriage as Planet and Sky. There have been some struggles along the way, some adjustments necessary to become a harmonious union, but now they are happy and whole. In our next episode, the world they have formed together has matured and now includes a healthy and thriving biosphere. Planet is so grateful to his heavenly companion that he is inspired to compose an ode to his love for her. Join us again for the further adventures of Planet and Sky.
            </p>
          </div>
        </div>
      </div>
  </section>
  </Layout>
)

export default Episode4
